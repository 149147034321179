input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.input-form {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
}

.input-form__icon {
    flex: none;
    margin-right: 12px;
}

.input-form__icon i {
    color: #878787;
}

.input-form__control {
    flex: auto;
    position: relative;
}

.input-form__control input {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #878787;
}

.input-form__control input:active, .input-form__control input:focus {
    border-bottom: 1px solid #4285F4;
}

.input-form__control label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    color: #b8b8b8;
    cursor: text;
    transition: .2s;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.input-form--active .input-form__control input {
    border-bottom: 1px solid #4285F4;
}

.input-form--active .input-form__control label {
    position: absolute;
    top: -14px;
    font-size: 12px;
    color: #4285F4 !important;
}

.input-form--active .input-form__icon i {
    color: #4285F4 !important;
}

.input-form--not-empty .input-form__control label {
    position: absolute;
    top: -14px;
    font-size: 12px;
}

.input-form__error{
    position: absolute;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #CC0000;
}

.input-form--error .input-form__icon i,
.input-form--error .input-form__control label {
    color: #CC0000 !important;
}

.input-form--error .input-form__control input {
    border-bottom: 1px solid #CC0000;
}