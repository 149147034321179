.cursor-tip{
    position: fixed;
    z-index: 10000;
    width: 320px;
    border-radius: 8px;
    font-size: 16px;
    color: #49505b;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25),0 3px 10px 0 rgba(0,0,0,.2)!important;
}

.cursor-tip__header{
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25),0 3px 10px 0 rgba(0,0,0,.2)!important;
}

.cursor-tip__header-title{
    font-size: 28px;
    color: #49505b;
}

.cursor-tip__header-desc{
    color: #49505b;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.cursor-tip__header-square{
     color: #49505b;
     font-size: 24px;
 }

.cursor-tip__header-price{
    color: #49505b;
    font-size: 22px;
}

.cursor-tip__body{
    padding: 20px 16px 16px;
}

.cursor-tip__body-item__name{
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    border-bottom: 1px solid #c1c1c1;
}

.cursor-tip__body-item__desc{
    font-size: 10px;
    color: #7b7b7b;
    text-align: right;
}