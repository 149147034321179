.object_building.floorCard {}
.object_building.floorCard .someinfo{
    /*margin-right: -20px;*/
    /*margin-top: -20px;*/
    display: flex;
    justify-content: right;
}
.object_building.floorCard .someinfo div.cell{
    text-align: center;
    width: 160px;
    margin: 0 5px;
    box-shadow: 0 0 10px 0 #ddd;
    border-radius: 16px;
}
.object_building.floorCard .someinfo span.number {
    color: #4285F4;
    font-size: 1.5rem;
    display: block;
    padding: 20px 0 8px 0
;
}
.object_building.floorCard .someinfo span.number .units{
    font-size: 1rem;
}
.object_building.floorCard .someinfo span.cell{
    color: #4285F4;
    font-size: 0.8rem;
    padding: 0 16px 16px 16px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
}
.selectedRoom {
    width: 70%;
    padding: 20px;
}
.selectedRoom table.table td {
    padding: 0.6rem;
}
i.delete-floor.fas.fa-trash:hover {
    font-size: 2rem;
    transition-property: font-size;
    transition-duration: 300ms;
    color: rgb(255, 104, 117);
}
i.delete-floor.fas.fa-trash {
    position: absolute;
    bottom: 16px;
    right: 32px;
    font-size: 1.5rem;
    color: #999;
    transition-property: font-size;
    transition-duration: 500ms;
}
.form-small-header.color-grey {
    color: #999;
    font-size: 1.25rem;
}
span.form-small-header.label-form {
    width: 100%;
    display: block;
    color: #999;
    font-size: 0.85rem;
}