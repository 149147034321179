.object__row.row {
  margin-right: 0px;
  margin-left: 0px;
}
.object__row.col {
  padding-left: 0;
}
/*.object {*/
/*  overflow: hidden;*/
/*  margin-bottom: 18px;*/
/*  border-radius: 10px;*/
/*}*/
/*.object img {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/*.object h5,*/
/*.object h4,*/object__row
/*.object h2 {*/
/*  line-height: 1;*/
/*  text-align: center;*/
/*}*/

.object_card {

}

.object_card .clickable_area {
  cursor: pointer;
}
.object__row {
  height: 100%;
}
.object__description {
  border-radius: 10px;
  align-items: center;
  z-index: 300;
  background-color: #fff;
  color: #424242;
  height: 100%;
}
.object__image-block {
  height: 200px;
}
.object__image {
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.description__col {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.description__col--centered {
  align-items: center;
}
.description__title {
  margin: 25px 0;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: pre-line;
  cursor: pointer;
}
.description__title h5 {
  word-wrap: break-word;
  white-space: pre-line;
}
.description__amount {
  font-size: 19px;
}
.description__amount-tag {
  font-size: 9px;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.description__adress {
  font-size: 12px;
  margin-bottom: 30px;
  text-align: center;
}
.info {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}
.info__left-column {
  display: flex;
}
.info__text {
  font-size: 13px;
  margin-left: 14px;
  margin-bottom: 8px;
}

.description__area {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  background: url("bgobjects.png") no-repeat left 70%;
  text-align: center;
}

.flag__area {
  line-height: 1;
  font-size: 11px;
  text-transform: uppercase;
  color: #f8f9fa;
  margin-bottom: 8px;
}
.flag__weight {
  font-size: 18px;
  font-weight: bold;
  color: #f8f9fa;
}
.flag__sup-message {
  font-size: 14px;
}
.object_dropdown {
  position: absolute;
  bottom: 8px;
  right: 24px;
}
.object_dropdown__button {
  background-color: transparent;
  border: none;
  color: #f8f9fa;
  font-size: 20px;
}
.object_dropdown__block {
  display: block;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: -97px;
  right: -200px;
  box-shadow: 20px 20px 50px 50px rgb(0 0 0 / 16%),
    0 20px 10px 0 rgb(0 0 0 / 12%);
  padding: 12px;
  transition: 0.4s ease-in-out;
}
.object_dropdown__option {
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
  border: none;
  background-color: transparent;
  z-index: 10000;
}
.object_dropdown__option--blue:hover {
  color: #4285f4;
}
.object_dropdown__option--red:hover {
  color: rgb(255, 104, 117);
}
.object_dropdown__option p {
  margin-bottom: 0;
  margin-left: 3px;
  line-height: 2.3;
}

.no-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.2px;
  background: url("./pngegg.png") rgb(0 0 0 / 16%) no-repeat center;
}


img.card-img-top.w-100 {
  height: 100%;
}
.object-card.modal-image-layer {
  width: 80%;
  height: 80%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 10%;
  box-shadow: 0 0 60px 0px #404040;
  border-radius: 24px;
}
.object-card.image-layer {
  width: 100%;
  height: 100%;
  border-radius: 24px;
}
.object-card.image_gallery_preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  height: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .object {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }
  .object__image-block {
    height: 200px;
  }
  .description__area {
    height: 120px;
    background: url("bgobjects.png") no-repeat center top -3%;
  }
}
