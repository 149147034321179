.file-input-b{
    position: relative;
    min-height: 100px;
    background: #ececec;
    border-radius: 4px;
}

.file-input-b input{
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.file-input-b__label{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 24px;
    text-align: center;

}

.file-input-b__desc{
    font-size: 12px;
}

.file-b {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.file-b__img{
    position: relative;
    flex: none;
    width: 52px;
    height: 52px;
    background: #ececec;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 900;
    color: #7b7b7b;
}

.file-b__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.file-b__name{
    flex: auto;
    padding: 12px;
}

.file-b__rm {
    flex: none;
    color: #9a9a9a;
    padding: 8px;
}
