.floor-planner__controls{
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 8px;
    background: #dadada;
}

.floor-planner__controls-side{
    display: flex;
}