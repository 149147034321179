.card .card-body h2.room_card_header_2 {
    font-weight: 300;
    font-size: 1.25rem;
}

.card .card-body h3.room_card_header_2 {
    font-weight: 300;
    font-size: 1rem;
}

.card.room {
    border-radius: 1.5rem;
}

.card.room .room_card_image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    border-radius: 1.5rem;
}

.card.room .room_card_data {
    height: 100%;
    padding: 2rem;
}
.btn.no-shadow {
    box-shadow: unset;
}
.breadcrumb_secondary_name {
    color: #777;
    font-size: 1rem;
    font-weight: 300!important;
}
.card.room .room_card_data .rentalObject_header_2 {
    text-transform: uppercase;
    color: #6c757d;
    font-size: 1.75rem;
}

.room_card_image span.room_squre, .room_card_image span.room_amountRent  {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.75);
    border: #FFF 1px solid;
    border-radius: 0.8rem;
    font-size: 2rem;
    color: #555;
    position: absolute;
    bottom: 30px;
    box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.4);
}
.room_card_image span.room_squre {
    left: 30px;
}
.room_card_image span.room_amountRent {
    right: 30px;
}

span.room_units {
    font-size: 1.4rem;
}
.room_text_block {
    font-size: 0.8rem;
}
.room.table i.fa, .room.table i.fas {
    font-size: 1.25rem;
    color: #cacaca;
}
.room_card_header_2 i.fas {
    font-size: 1.25rem;
    color: #cacaca;
}
.fas.fa-wrench.update_icon {
    color: #cacaca;
    cursor: pointer;
}
.fas.fa-wrench.update_icon:hover, .rentalObject-icon-download:hover {
    color: #4265D6;
}
.rentalObject-icon-download {
    color: #cacaca;
    font-size: 1.75rem;
    cursor: pointer;
}
span.rental-object-list.total-row-of-table {
    font-weight: 600;
    font-size: 1rem;
}

span.rental-object-list.total-row-of-table.total-factually-rents {
    color: #2c6239;
}
span.rental-object-list.total-row-of-table.total-square {
    color: #4573be;
}

@media (max-width: 768px) {
    .card.room .room_card_data .rentalObject_header_2 {
        font-size: 1.5rem;
    }
}
@media (max-width: 998px) {
    .card.room .room_card_data .rentalObject_header_2 {
        font-size: 1.5rem;
    }
    .room.card {
        margin-bottom: 30px;
    }
}
