body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.centered-input-filed-50{
  display: flex;
  align-items: center;
  height: 50px;
}
table.smart-table tbody tr td {
  position: relative;
}

table.smart-table .smart-table__td  {
  padding-top: 8px!important;
  padding-bottom: 8px!important;
  padding-right: 16px;
  vertical-align: middle;
}

table.smart-table .smart-table__td:after, table.smart-table .smart-table__td:before{
  bottom: 50%!important;
  transform: translateY(50%);
}



.indicator{
  width: 10px;
  height: 10px;
  border-radius: 50% !important;
}