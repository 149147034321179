.floor-updating-form {
    padding: 8px 32px;
    font-size: 1.2rem;
    color: #007bff;
}
.floor-updating-form i.fas {
    font-size: 1.3rem;
}
.floor-update-scheme.ruonded-success-create-btn {
    padding: 12px 16px;
    margin-right: 16px;
}