.my-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  min-width: 28px;
  border-radius: 8px;
  border: 1px solid #282c34;
  font-size: 14px;
  cursor: pointer;
}

.my-btn:disabled {
  background: rgba(181, 181, 181, 0.95);
  border: 1px solid #9c9c9c;
}

.my-btn--transparent {
  background: none;
  color: #676767;
  border: 1px solid #676767;
}

.my-btn--primary {
  background: #037bff;
  color: #fff;
  border: 1px solid #0065d4;
}

.my-btn--green {
  background: #9bd093;
  color: #fff;
  border: 1px solid #7aaf73;
}

.my-btn__icon {
  display: flex;
  height: 16px;
  margin-right: 8px;
}

.my-btn__icon--no-margin {
  margin: 0;
}

.my-btn__icon svg {
  position: relative;
  height: 100%;
}
