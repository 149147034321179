.contract-table tr:first-child td {
    border-top: none;
}

.contract-table tr{
    position: relative;
}

.contract-table tr{
    width: 100%;
}

.contract-table tr td:first-child{
    flex: none;
    width: 200px;
}

.contract-table tr td:last-child{
    flex: auto;
    width: calc(100% - 200px);

}


.contract-table td{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
