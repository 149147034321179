.pt-0 {padding-top: 0!important;}
.pt-5 {padding-top: 5px!important;}
.pt-15 {padding-top: 15px!important;}
.pt-20 {padding-top: 20px!important;}
.pt-30 {padding-top: 30px!important;}
.pt-40 {padding-top: 40px!important;}
.pt-60 {padding-top: 60px!important;}
.pt-90 {padding-top: 90px!important;}
.pt-120 {padding-top: 120px!important;}
.pb-0 {padding-bottom: 0!important;}
.pb-15 {padding-bottom: 15px!important;}
.pb-20 {padding-bottom: 20px!important;}
.pb-30 {padding-bottom: 30px!important;}
.pb-40 {padding-bottom: 40px!important;}
.pb-60 {padding-bottom: 60px!important;}
.pb-90 {padding-bottom: 90px!important;}
.pr-0 {padding-right: 0!important;}
.pr-5 {padding-right: 5px!important;}
.pr-10 {padding-right: 10px!important;}
.pr-15 {padding-right: 15px!important;}
.pr-20 {padding-right: 20px!important;}
.pr-25 {padding-right: 25px!important;}
.pr-30 {padding-right: 30px!important;}
.pr-45 {padding-right: 45px!important;}
.pr-60 {padding-right: 60px!important;}
.pr-80 {padding-right: 80px!important;}
.pr-90 {padding-right: 90px!important;}
.pr-100 {padding-right: 100px!important;}
.pr-120 {padding-right: 120px!important;}
.pr-150 {padding-right: 150px!important;}
.pl-0 {padding-left: 0!important;}
.pl-5 {padding-left: 5px!important;}
.pl-10 {padding-left: 10px!important;}
.pl-15 {padding-left: 15px!important;}
.pl-20 {padding-left: 20px!important;}
.pl-25 {padding-left: 25px!important;}
.pl-30 {padding-left: 30px!important;}
.pl-45 {padding-left: 45px!important;}
.pl-60 {padding-left: 60px!important;}
.pl-80 {padding-left: 80px!important;}
.pl-90 {padding-left: 90px!important;}
.pl-100 {padding-left: 100px!important;}
.pl-120 {padding-left: 120px!important;}
.pl-150 {padding-left: 150px!important;}
.px-0{padding-left:0!important;padding-right:0!important;}
.px-5{padding-left:5px !important;padding-right:5px !important}
.px-10{padding-left:10px !important;padding-right:10px !important}
.px-15{padding-left:15px !important;padding-right:15px !important}
.px-20{padding-left:20px !important;padding-right:20px !important}
.px-25{padding-left:25px !important;padding-right:25px !important}
.px-30{padding-left:30px !important;padding-right:30px !important}
.px-45{padding-left:45px !important;padding-right:45px !important}
.px-60{padding-left:60px !important;padding-right:60px !important}
.px-80{padding-left:80px !important;padding-right:80px !important}
.px-90{padding-left:90px !important;padding-right:90px !important}
.px-100{padding-left:100px !important;padding-right:100px !important}
.px-120{padding-left:120px !important;padding-right:120px !important}
.px-150{padding-left:150px !important;padding-right:150px !important}
.py-0{padding-top:0!important;padding-bottom:0!important;}
.py-5{padding-top:5px !important;padding-bottom:5px !important}
.py-8{padding-top:8px !important;padding-bottom:8px !important}
.py-10{padding-top:10px !important;padding-bottom:10px !important}
.py-15{padding-top:15px !important;padding-bottom:15px !important}
.py-16{padding-top:16px !important;padding-bottom:16px !important}
.py-20{padding-top:20px !important;padding-bottom:20px !important}
.py-24{padding-top:24px !important;padding-bottom:24px !important}
.py-25{padding-top:25px !important;padding-bottom:25px !important}
.py-30{padding-top:30px !important;padding-bottom:30px !important}
.py-32{padding-top:32px !important;padding-bottom:32px !important}
.py-45{padding-top:45px !important;padding-bottom:45px !important}
.py-60{padding-top:60px !important;padding-bottom:60px !important}
.py-80{padding-top:80px !important;padding-bottom:80px !important}
.py-90{padding-top:90px !important;padding-bottom:90px !important}
.py-100{padding-top:100px !important;padding-bottom:100px !important}
.py-120{padding-top:120px !important;padding-bottom:120px !important}
.py-150{padding-top:150px !important;padding-bottom:150px !important}
.pa-0{padding:0!important;}
.pa-5{padding:5px !important}
.pa-8{padding:8px !important}
.pa-10{padding:10px !important}
.pa-15{padding:15px !important}
.pa-16{padding:16px !important}
.pa-20{padding:20px !important}
.pa-24{padding:24px !important}
.pa-25{padding:25px !important}
.pa-30{padding:30px !important}
.pa-45{padding:45px !important}
.pa-60{padding:60px !important}
.pa-80{padding:80px !important}
.pa-90{padding:90px !important}
.pa-100{padding:100px !important}
.pa-120{padding:120px !important}
.pa-150{padding:150px !important}
