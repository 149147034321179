.none-break-word{
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.icon-24 svg{
    height: 24px;
}

.line-height-24{
    line-height: 24px;
}

.cursor-tip{
    position: fixed;
    z-index: 10000;
    width: 320px;
    border-radius: 8px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25),0 3px 10px 0 rgba(0,0,0,.2)!important;
}

.link-forward-icon{
    margin-top: -2px;
    margin-right: 8px;
}

.link-forward-icon svg{
    height: 14px;
}