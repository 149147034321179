.objects_create.card {
    border-radius: 1.5rem;
    padding: 20px;
}

.object-form{
    color: #00c851;
}
.objects-form__item-title {

}
.objects-form__item-inner {
    flex: auto;
    margin-top: 16px;
    border-radius: 1rem;
    border: 1px solid #E7E7E7;
    padding: 24px;
}
.object-form.button_plus {
    width: 32px;
    padding: 5px 0;
    border-radius: 50%;
}
.object_update.card {
    border-radius: 16px;
    padding: 24px 16px;
}
.object_dropdown .object_update {
    position: unset;
    top:unset;
    right:unset;
    color: unset;
}
.object_update div.md-form {
    margin-top: 0;
    margin-bottom: 0;
}
.object_update>.md-form.validate {
    margin-bottom: 2rem;
}
.object_update.button_delete {
    background-color: #fff;
    padding: 8px 16px;
    border: unset;
    position: relative;
    top: 30px;
    right: -30px;
    color: #819098;
}
.object_update.button_delete:hover {
    color: rgb(255, 104, 117);
}
h3.object_update.block_title {
    margin:  0 0 0 16px;
    display: inline-block;
}
button.btn_building_create {
    top: -5px;
}
.card.object_update.mb-30.selected {
    border-right: 8px solid #007bff;
}
.card.card-cascade.wider.card_building {

}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0.1rem;
    list-style: none;
    background-color: unset;
    border-radius: unset;
}
.card.card-cascade.wider.card_building .card-body.card-body-cascade:hover {
    cursor: pointer;
    border-bottom: 3px solid #007bff;
}
.breadcrumb_object_card .fa-wrench {
    color: #CCC;
}
.breadcrumb_object_card .fa-wrench:hover {
    color: #007bff;
}
.breadcrumb_object_card i.fa.fa-trash-alt.fa-2x {
    color: #CCC;
}
.breadcrumb_object_card i.fa.fa-trash-alt.fa-2x:hover {
    color: rgb(255, 104, 117);
}
span.link_to_breadcrumbs {
    color: #007bff;
}
span.link_to_breadcrumbs:hover {
    color: #0056b3;
    cursor: pointer;
}
.mdbcarousel.object_form_update {border-radius: 16px 16px 0 0;}
.br-16 {border-radius: 16px 16px 0 0}
.object_form.file-input-b {
    position: relative;
    min-height: 200px;
    background: #ececec;
    border-radius: 0 0 16px 16px;

}
