.align-center{
    align-items: center;
}


.justify-end{
    justify-content: flex-end;
}

.justify-center{
    justify-content: center;
}

.justify-between{
    justify-content: space-between;
}

.flex-column{
    flex-direction: column;
}

.flex-wrap{
    flex-wrap: wrap;
}