.owners-form {
    display: grid;
    grid-template-areas: "bi re co" "bi notes notes" "controls controls controls";
    grid-template-rows: 2fr 1fr;
    grid-auto-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
}

@media screen and (max-width: 1024px) {
    .owners-form {
        grid-template-areas: "bi" "re" "co" "notes" "controls";
        grid-template-rows: 1fr;
        grid-auto-columns: 1fr;
    }
}

.owners-form__item{
    display: flex;
    flex-direction: column;
}

.owners-form__item-title{
    flex: none;
    font-size: 18px;
    color: #7D7D7D;
    line-height: 32px;
    text-transform: uppercase;
}

.owners-form__item-inner{
    flex: auto;
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    padding: 24px;
}

.owners-form__item-row{
    display: flex;
}

.owners-form__item-row + .owners-form__item-row{
    margin-top: 16px;
}

.owners-form__item-field{
    flex: auto;
}

.owners-form__item-field+.owners-form__item-field{
    margin-left: 24px;
}

.owners-form__basic-info {
    grid-area: bi;
}

.owners-form__requisites {
    grid-area: re;
}

.owners-form__contacts {
    grid-area: co;
}

.owners-form__controls {
    grid-area: controls;
    display: flex;
    justify-content: space-between;
}

.owners-form__notes {
    grid-area: notes;
}
.owners-form__notes .owners-form__item-inner{
    padding: 8px !important;
}

.owners-form__notes textarea{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(108, 117, 125, 0.06);
}