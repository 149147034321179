button.smart-table_filters:hover {
    box-shadow: 0 2px 5px 0 #5a95f5;
}
button.smart-table_filters {
    font-size: 0.7rem;
    padding: 2px 25px;
    line-height: 0.3rem;
    margin: 0 15px 0 0;
}
button.smart-table_filters.activeRadio {
    background-color: #5a95f5!important;
    color: #fff!important;
    box-shadow: inset 0 2px 5px 0 #3a5f9b;
}