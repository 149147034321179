.mt-0 {margin-top: 0!important;}
.mt-15 {margin-top: 15px!important;}
.mt-16 {margin-top: 16px!important;}
.mt-20 {margin-top: 20px!important;}
.mt-30 {margin-top: 30px!important;}
.mt-40 {margin-top: 40px!important;}
.mt-60 {margin-top: 60px!important;}
.mt-90 {margin-top: 90px!important;}
.mt-120 {margin-top: 120px!important;}
.mb-0 {margin-bottom: 0!important;}
.mb-5 {margin-bottom: 5px!important;}
.mb-8 {margin-bottom: 8px!important;}
.mb-15 {margin-bottom: 15px!important;}
.mb-16 {margin-bottom: 16px!important;}
.mb-20 {margin-bottom: 20px!important;}
.mb-24 {margin-bottom: 24px!important;}
.mb-30 {margin-bottom: 30px!important;}
.mb-40 {margin-bottom: 40px!important;}
.mb-60 {margin-bottom: 60px!important;}
.mb-90 {margin-bottom: 90px!important;}
.mr-0 {margin-right: 0!important;}
.mr-5 {margin-right: 5px!important;}
.mr-8 {margin-right: 8px!important;}
.mr-10 {margin-right: 10px!important;}
.mr-15 {margin-right: 15px!important;}
.mr-16 {margin-right: 16px!important;}
.mr-20 {margin-right: 20px!important;}
.mr-24 {margin-right: 24px!important;}
.mr-25 {margin-right: 25px!important;}
.mr-30 {margin-right: 30px!important;}
.mr-45 {margin-right: 45px!important;}
.mr-60 {margin-right: 60px!important;}
.mr-80 {margin-right: 80px!important;}
.mr-90 {margin-right: 90px!important;}
.mr-100 {margin-right: 100px!important;}
.mr-120 {margin-right: 120px!important;}
.mr-150 {margin-right: 150px!important;}
.ml-0 {margin-left: 0!important;}
.ml-5 {margin-left: 5px!important;}
.ml-6 {margin-left: 6px!important;}
.ml-8 {margin-left: 8px!important;}
.ml-10 {margin-left: 10px!important;}
.ml-15 {margin-left: 15px!important;}
.ml-16 {margin-left: 16px!important;}
.ml-20 {margin-left: 20px!important;}
.ml-24 {margin-left: 24px!important;}
.ml-25 {margin-left: 25px!important;}
.ml-30 {margin-left: 30px!important;}
.ml-45 {margin-left: 45px!important;}
.ml-60 {margin-left: 60px!important;}
.ml-80 {margin-left: 80px!important;}
.ml-90 {margin-left: 90px!important;}
.ml-100 {margin-left: 100px!important;}
.ml-120 {margin-left: 120px!important;}
.ml-150 {margin-left: 150px!important;}
.my-0 {margin-top: 0!important;margin-bottom: 0!important;}
.my-5 {margin-top: 5px!important;margin-bottom: 5px!important;}
.my-6 {margin-top: 6px!important;margin-bottom: 6px!important;}
.my-8 {margin-top: 8px!important;margin-bottom: 8px!important;}
.my-10 {margin-top: 10px!important;margin-bottom: 10px!important;}
.my-15 {margin-top: 15px!important;margin-bottom: 15px!important;}
.my-16 {margin-top: 16px!important;margin-bottom: 16px!important;}
.my-20 {margin-top: 20px!important;margin-bottom: 20px!important;}
.my-24 {margin-top: 24px!important;margin-bottom: 24px!important;}
.my-25 {margin-top: 25px!important;margin-bottom: 25px!important;}
.my-30 {margin-top: 30px!important;margin-bottom: 30px!important;}
.my-45 {margin-top: 45px!important;margin-bottom: 45px!important;}
.my-60 {margin-top: 60px!important;margin-bottom: 60px!important;}
.my-80 {margin-top: 80px!important;margin-bottom: 80px!important;}
.my-90 {margin-top: 90px!important;margin-bottom: 90px!important;}
.my-100 {margin-top: 100px!important;margin-bottom: 100px!important;}
.my-120 {margin-top: 120px!important;margin-bottom: 120px!important;}
.my-150 {margin-top: 150px!important;margin-bottom: 150px!important;}
.mx-0 {margin-left: 0!important;margin-right: 0!important;}
.mx-5 {margin-left: 5px!important;margin-right: 5px!important;}
.mx-6 {margin-left: 6px!important;margin-right: 6px!important;}
.mx-8 {margin-left: 8px!important;margin-right: 8px!important;}
.mx-10 {margin-left: 10px!important;margin-right: 10px!important;}
.mx-15 {margin-left: 15px!important;margin-right: 15px!important;}
.mx-16 {margin-left: 16px!important;margin-right: 16px!important;}
.mx-20 {margin-left: 20px!important;margin-right: 20px!important;}
.mx-24 {margin-left: 24px!important;margin-right: 24px!important;}
.mx-25 {margin-left: 25px!important;margin-right: 25px!important;}
.mx-30 {margin-left: 30px!important;margin-right: 30px!important;}
.mx-45 {margin-left: 45px!important;margin-right: 45px!important;}
.mx-60 {margin-left: 60px!important;margin-right: 60px!important;}
.mx-80 {margin-left: 80px!important;margin-right: 80px!important;}
.mx-90 {margin-left: 90px!important;margin-right: 90px!important;}
.mx-100 {margin-left: 100px!important;margin-right: 100px!important;}
.mx-120 {margin-left: 120px!important;margin-right: 120px!important;}
.mx-150 {margin-left: 150px!important;margin-right: 150px!important;}